<template>
  <div>
    <div class="navi-link" @click.stop="dialog = true">
      <span class="navi-icon">
        <i class="flaticon-edit-1 text-warning"></i>
      </span>
      <span class="navi-text">{{ $t('ACTION.UPDATE') }} </span>
    </div>
    <v-dialog v-model="dialog" max-width="350">
      <v-card>
        <v-card-title class="headline"
          >Mulchilik turini o'zgartirish</v-card-title
        >
        <v-card-text>
          <v-text-field
            label="Mulchilik nomi"
            v-model="getData.name"
            dense
            outlined
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error" @click="cancel"> Bekor qilish </v-btn>

          <v-btn color="success" @click="submit"> Saqlash </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false
    }
  },
  created() {},
  computed: {
    getData() {
      return this.staffData
    }
  },
  methods: {
    cancel() {
      this.$store.dispatch('getAllOwner')
      this.dialog = false
    },
    submit() {
      const data = {
        name: this.getData.name
      }
      this.$store.dispatch('ownerUpdate', { data: data, id: this.getData.id })
      this.dialog = false
    }
  },
  props: {
    staffData: {
      type: Object
    }
  }
}
</script>

<style scoped>
.navi .navi-item .navi-link {
  padding: 0.75rem 1rem;
}
</style>
