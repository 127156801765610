<template>
  <div>
    <v-btn color="primary" small @click.stop="dialog = true">
      {{ $t('MENU.ENTERPRICE.CLIENTS.FORM') }}+
    </v-btn>

    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="headline">Mulchilik qoshish</v-card-title>
        <v-card-text>
          <v-text-field
            label="Mulchilik nomi"
            v-model="ownerInput"
            dense
            outlined
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error"
            :disabled="newMulkchilikLoading"
            small
            @click="dialog = false"
          >
            Bekor qilish
          </v-btn>

          <v-btn
            color="primary"
            small
            @click="submit"
            :disabled="ownerInput == '' || newMulkchilikLoading"
          >
            <i v-if="newMulkchilikLoading" class="el-icon-loading"></i> Yuborish
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newMulkchilikLoading: false,
      dialog: false,
      ownerInput: ''
    }
  },
  created() {
    this.$store.dispatch('staffDepartments')
  },
  methods: {
    submit() {
      const data = {
        name: this.ownerInput
      }
      this.newMulkchilikLoading = true
      this.$store
        .dispatch('ownerCreate', data)
        .then(() => {
          this.newMulkchilikLoading = false
          this.ownerInput = ''
          this.dialog = false
        })
        .catch((err) => {
          this.newMulkchilikLoading = false
          console.error(err)
        })

      console.log(data)
    }
  }
}
</script>

<style></style>
